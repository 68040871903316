.section-ingredients {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--container-gap);
    background: radial-gradient(ellipse at 50% 50%, #ccf0f5 0%, #fff 50%, #fff 30%);

    @include col-xs-sm() {
        padding-top: 10vh;
        padding-bottom: 10vh;
        flex-direction: column-reverse;
    }

    .section-ingredients__comp {
        width: 50%;
        position: relative;
        z-index: 3;
        mix-blend-mode: darken;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        .section-ingredients__comp--bg {
            position: relative;
            z-index: 1;
        }
        .section-ingredients__comp--pp {
            position: absolute;
            z-index: 3;
            width: 70%;
            bottom: 10%;
            left: 0;
        }
        .section-ingredients__comp--blur {
            position: absolute;
            z-index: 2;
            width: 20%;
            top: 20%;
            right: 0;
            filter: blur(4px);
        }
        @include col-xs-sm() {
            width: 100%;
        }

    }

    .section-ingredients__content {
        position: relative;
        z-index: 4;
        width: 42%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        @include col-xs-sm() {
            width: 100%;
        }
    }

    .section-ingredients__schema {
        margin-top: 7vh;
        width: 80%;

        @include col-xs-sm() {
            width: 100%;
        }
    }

    .section-title {
        margin-bottom: 50px;
    }

    .section-text {
        text-align: right;
    }
}
