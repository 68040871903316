.socials {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 40px;
    a {
        display: block;
        height: 50px;
        width: 50px;
        margin-left: 12px;
        &:first-child {
            margin-left: 0;
        }
        svg {
            width: auto;
            height: 100%;
            display: block;
            margin: 0 auto;
            path {
                fill: $color_purple;
                transition: fill 0.4s;
                transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
            }
        }
        &:hover {
            svg {
                path {
                    fill: $color_gold;
                }
            }
        }
        
    }

    @include col-xs() {
        //flex-direction: column;
        a {
            margin-left: 15px;
            // margin-top: 10px;
            height: 16px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}