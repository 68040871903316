.section-idea {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 var(--container-gap) 15vh;

    background: radial-gradient(ellipse at 100% -70%, #ccf0f5 0%, #fff 50%, #fff 30%);

    @include col-xs-sm() {
        padding-bottom: 16vh;
        flex-direction: column-reverse;
    }

    .section-idea__line {
        position: absolute;
        bottom: 15vh;
        left: 0;
        width: calc( 100% - var(--container-gap) );
        height: 1px;
        z-index: 5;
        background-color: $primary_color;

        @include col-xs-sm() {
            bottom: auto;
            top: 60vh;
            // width: 100%;
        }
    }

    .section-idea__content {
        position: relative;
        z-index: 4;
        width: 50%;
        padding-top: 15%;
        padding-bottom: 15%;

        @include col-xs-sm() {
            width: 100%;
            padding-top: 15vh;
            padding-bottom: 0;
        }
        

        .section-title {
            margin-bottom: 50px;
            display: inline-flex;
        }

        .section-text {
            text-align: left;
        }

        .btn-video {
            @include font-bold();
            font-size: 16px;
            text-transform: uppercase;
            display: inline-flex;
            align-items: center;
            margin-top: 50px;
            span {
                @include transition(1s);
                color: $primary_color;
                margin-right: 15px;
            }
            svg {
                @include transition(0.3s);
                path {
                    @include transition(1s);
                    fill: $primary_color;
                }
            }
            &:hover {
                span {
                    color: darken($primary_color, 10);
                }
                svg {
                    rotate: 360deg;
                    path {
                        fill: darken($primary_color, 10);
                    }
                }
            }
        }
    }

    .section-idea__img {
        width: 50%;
        position: relative;
        z-index: 3;
        padding-top: 20%;
        overflow: hidden;

        @include col-xs() {
            width: 100%;
            left: 0;
            height: 60vh;
        }

        @include col-sm() {
            width: 80%;
            left: 20%;
            height: 60vh;
        }

        .section-idea__img--bg {
            position: absolute;
            top: 0;
            right: 0;
            width: 40%;
            height: 100%;
            z-index: 2;
            background-color: $primary_color;

            .section-idea__img--bg--firm {
                position: absolute;
                top: 0;
                right: 30px;
                border-right: 1px solid $bianco;
                padding-top: 5vh;
                padding-right: 20px;
                width: 90px;

                @include col-xs-sm() {
                    width: 60px;
                }
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }

        .section-idea__img--1 {
            position: absolute;
            z-index: 3;
            bottom: 0;
            width: 70%;
            height: 100%;
            right: 10%;

            // display: flex;
            // justify-content: flex-end;


            @include col-xs() {
                width: 70%;
            }

            @include col-sm() {
                width: 80%;
            }

            img {
                position: relative;
                z-index: 10;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: contain;
                object-position: 100% 100%;
            }
        }
        
        .section-idea__img--2 {
            position: absolute;
            left: 0;
            z-index: 1;
            width: 70%;
            bottom: 0;
            overflow: hidden;
    
            @include col-xs() {
                width: 100%;
                // bottom: auto;
                // top: 28vh;
            }
    
            @include col-sm() {
                // right: 40%;
                // top: 16.6vh;
            }
    
            img {
                width: 100%;
                height: auto;
                display: block;
                position: relative;
                transform: translateY(18%);
            }
        }
    }


    .section-idea__img--3 {
        position: absolute;
        right: 30%;
        z-index: 5;
        width: 35%;
        bottom: 5vh;

        @include col-xs() {
            right: 5%;
            width: 80%;
            bottom: auto;
            top: 50vh;
        }

        @include col-sm() {
            right: 5%;
            width: 60%;
            bottom: auto;
            top: 50vh;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }


    
}
