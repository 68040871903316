#overlay-video {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99651;
    display: none;

    .overlay-video--yt {
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .overlay-video--yt__inner {
            position: relative;
            max-width: 1200px;
            width: 100%;
            display: none;
            youtube-video {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: auto;
                display: block;
                padding-top: 56.25%;
                z-index: 1;

                background-color: aqua;
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

    }

    .overlay-video__bg {
        background-color: $nero;
        display: none;
        height: 100%;
        right: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
    
    .overlay-video__close {
        cursor: pointer;
        display: none;
        height: 40px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 40px;
        z-index: 3;

        &::after {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43.707' height='43.707' viewBox='0 0 43.707 43.707'%3E%3Cg transform='translate(-1468.146 -100.146)'%3E%3Cline x2='43' y2='43' transform='translate(1468.5 100.5)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3Cline x1='43' y2='43' transform='translate(1468.5 100.5)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E");            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            @include transition();
        }
        &:hover {
            &::after {
                @include rotate(90deg);
            }
        }

        @include col-xs() {
            width: 30px;
            height: 30px;
        }
    }
}