$nero: #323230;
$bianco: #fff;
$beige_chiaro: #B4A28B;
$beige_scuro: #71675A;
$blu: #44687D;
$giallo: #E1A003;
$rosso: #990000;
$bg_color: #EFDEC8;


$color_gold: #C8A573;
$color_purple: #5F2567;


//$primary_color: #23A8A8;
$primary_color: #00a4c7;

$wrapper_width: 1920px;


@mixin col-() {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin col-sm() {
    @media (min-width: 481px) and (max-width: 991px) {
        @content;
    }
}

@mixin col-md() {
    @media (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}

@mixin col-lg() {
    @media (min-width: 1200px) and (max-width: 1600px) {
        @content;
    }
}

@mixin col-xs-sm() {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin col-xs() {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin col-xxs() {
    @media (max-width: 350px) {
        @content;
    }
}

@mixin col-xl() {
    @media (min-width: 1601px) {
        @content;
    }
}



//
//
//
:root {
    --container-gap: 12%;
    --header-width: 100px;
    --header-height: 100vh;
    --main-width: calc( 100% - var(--header-width) );
    --main-left: var(--header-width);
/*     --delay: 0;
    --duration: 800ms;
    --iterations: 1; */
    --header-padding: 30px;
    --header-label-size: 16px;
    --header-logo-size: 50%;
    --header-navigation-width: 60%;
    --header-navigation-height: 20px;
    //
    --progress-bar-height: 5px;
    --progress-bar-bottom: 0;
    //
    --section-title-size: 80px;
    --section-text-size: 24px;
    //
    --hero-bar-width: 200px;
    --hero-bar-label-size: 14px;
    --hero-bar-label-rotate: rotate(180deg);

    --hero-bar-padding: 30px 0;
}

@include col-xs-sm() {
     :root {
        --container-gap: 25px;
        //
        --header-width: 100vw;
        --header-height: 60px;
        --header-padding: 20px;
        --header-logo-size: calc( var(--header-height) - 20px );
        --header-navigation-width: 50px;
        --header-navigation-height: 16px;
        //
        --progress-bar-height: 3px;
        --progress-bar-bottom: var(--header-height);
        //
        --main-width: 100vw;
        --main-left: 0;
        //
        --hero-bar-width: 100%;
        --hero-bar-label-size: 12px;
        --hero-bar-label-rotate: rotate(0);
        //
        --section-title-size: 60px;
        --section-text-size: 18px;
        //

    }
}

@include col-md() {
     :root {
         --container-gap: 6%;
         //
        --header-width: 60px;
        --header-padding: 20px;
        --header-label-size: 14px;
        --header-logo-size: 60%;
        --header-navigation-width: 70%;
        --header-navigation-height: 18px;
        //
        --section-title-size: 60px;
        --section-text-size: 18px;
        //
        --hero-bar-width: 140px;
        --hero-bar-label-size: 12px;
        --hero-bar-padding: 20px 0;
    }
}

@include col-lg() {
     :root {
        --header-width: 90px;
        --container-gap: 8%;
        //
        --hero-bar-width: 180px;
    }
}

@include col-xl() {
     :root {
        --header-width: 100px;
        --container-gap: 10%;
        //
    }
}
