html,
body {
    font-size: 16px;
    min-height: 100%;
    height: auto;
    overflow-x: hidden;
}

html {
    scroll-behavior: initial;
}

body {
    margin: 0;
    padding: 0;
    min-height: inherit;
    background-color: $bianco;
    width: 100vw;
    color: $nero;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility !important;
}

::-moz-selection { /* Code for Firefox */
    color: $primary_color;
    background: $nero;
}

::selection {
    color: $primary_color;
    background: $nero;
}

a {
    color: $primary_color;
    outline: none !important;
    text-decoration: none !important;
    @include transition(0.5s, color);

    &:hover {
        color: darken($primary_color, 10);
    }
}

div {
    outline: none !important;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.svg-container {
    display: inline-block;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    vertical-align: middle;
    width: 100%;

    svg {
        display: inline-block;
        left: 0;
        position: absolute;
        top: 0;
    }
}

#main {
    position: relative;
    width: var(--main-width);
    left: var(--main-left);
    overflow: hidden;
}

.wrapper-holder {
    padding-left: var(--container-gap);
    padding-right: var(--container-gap);
    .wrapper {
        //max-width: $wrapper_width;
        margin: 0 auto;
    }
}

.grid-container {
    margin-left: var(--container-gap);
    margin-right: var(--container-gap);
    position: relative;

    >.row {
        max-width: $wrapper_width;
        padding-left: -var(--container-gap);
        padding-right: -var(--container-gap);
    }
}

.img-fluid {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
}

img:not([src]):not([srcset]) {
    visibility: hidden;
}

button {
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
    outline-style: none;
    /* Internet Explorer  */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button {
    display: inline-flex;
    position: relative;
    transition-duration: 0.4s;
    overflow: hidden;
    height: 58px;
    width: auto;
    min-width: 180px;
    line-height: 58px;
    font-size: 14px;
    text-transform: uppercase;
    border: 1px solid $primary_color;
    background-color: $primary_color;
    color: $bianco !important;
    text-align: center;
    @include border-radius(6px, 6px, 6px, 6px);
    @include font-bold();
    @include transition(0.1s, all, cubic-bezier(0.86, 0, 0.07, 1));

    

    @include col-xs() {
        min-width: auto;
        padding: 0 10px;
        height: 50px;
        line-height: 44px;
        font-size: 14px;
        border-width: 3px;
    }

    &::before,
    & span {
        margin: 0 auto;
        transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
        transition-duration: 0.1s;
    }

    span {
        transform: translateY(0);
    }
    &::before {
        transform: translateY(100%);
    }

    &:hover {
        span {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
        &::before {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }

    &::before {
        pointer-events: none;
        content: attr(data-sm-link-text);
        color: $bianco;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        background-color: darken($primary_color, 10);
    }

    &.full {
        background-color: $nero;
        border-color: $nero;
        color: $bianco;
        &::before {
            background-color: $primary_color;
        }
    }

}

.parallax-img__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .parallax-img__img {
        --overflow: 80px;
        height: calc(100% + (2 * var(--overflow)));

        @include col-xs-sm() {
            --overflow: 100px;
            height: calc(100% + (2 * var(--overflow)));
        }

        position: absolute;
        width: 100%;
        will-change: transform;
        object-fit: cover;

    }
}

.page-content {
    min-height: 40vh;
    padding: 20vh 0 10vh 0;

    // h2 {
    //     font-weight: 300 !important;
    //     font-size: 72px;
    //     line-height: 1;
    //     color: $blu;
    //     text-transform: uppercase;
    //     margin-bottom: 25px;

    //     @include col-md() {
    //         font-size: 52px;
    //         line-height: 67px;
    //     }

    //     @include col-xs-sm() {
    //         font-size: 35px;
    //         line-height: 52px;
    //     }
    // }

    // p {
    //     font-size: 18px;
    //     line-height: 1.8;
    //     color: $blu;
    // }
}

.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    margin-left: -21px;
    margin-top: -21px;
    border: 4px solid $blu !important;
}

.el-anim {
    opacity: 0;
    transform: scale(1.2);
    @include transition(3s);
    &.loaded {
        opacity: 1;
        transform: scale(1);
    }
}

.el-anim-b {
    opacity: 0;
    transform: translateY(50px);
    @include transition(3s);
    &.loaded {
        opacity: 1;
        transform: translateY(0);
    }
}

.el-anim-d {
    opacity: 0;
    transform: translateY(50px);
    @include transition(3s);
    &.loaded {
        opacity: 1;
        transform: translateY(0);
    }
}

.el-anim-fade {
    opacity: 0;
    @include transition(3s);
    &.loaded {
        opacity: 1;
    }
}

.el-anim-left {
    opacity: 0;
    transform: translateX(-100px);
    @include transition(3s);
    &.loaded {
        opacity: 1;
        transform: translateX(0);
    }
}

.el-anim-right {
    opacity: 0;
    transform: translateX(100px);
    @include transition(3s);
    &.loaded {
        opacity: 1;
        transform: translateX(0);
    }
}

.line {
    transform-origin: 0% 50%;
    padding-bottom: 0.35rem;
    white-space: nowrap;
    will-change: transform;
}

.oh {
    overflow: hidden;
}

.oh__inner {
    display: inline-block;
    transform-origin: 0% 50%;
    will-change: transform;
}