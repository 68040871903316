#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $bianco;
    z-index: 9958;

    .preloader-logo {
        height: 100px;
        width: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -50px 0 0 -50px;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            object-position: 50% 50%;
        }
    }

    .preloader-progress {
        @include font-light();
        font-size: 3rem;
        position: absolute;
        bottom: 15px;
        right: 15px;
        text-align: right;
        color: $primary_color;
    }

    .preloader-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 3px;
        width: 0;
        background-color: $primary_color;
    }
}