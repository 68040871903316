@mixin linear-gradient($fromColor, $toColor) {
    background-color: $toColor;
    /* Fallback Color */
    background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor));
    /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient(top, $fromColor, $toColor);
    /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(top, $fromColor, $toColor);
    /* FF3.6 */
    background-image: -ms-linear-gradient(top, $fromColor, $toColor);
    /* IE10 */
    background-image: -o-linear-gradient(top, $fromColor, $toColor);
    /* Opera 11.10+ */
    background-image: linear-gradient(top, $fromColor, $toColor);
}

@mixin zoom-translation($val, $rot: 0) {
    -webkit-transform: perspective(1000px) translate3d(0, 0, $val) rotate($rot);
    transform: perspective(1000px) translate3d(0, 0, $val) rotate($rot);
}

@mixin zoom-overlay($val) {
    -webkit-transform: scale3d($val, $val, 1);
    transform: scale3d($val, $val, 1);
    transition: transform 0.35s;
}

@mixin border-radius($val1, $val2, $val3, $val4) {
    -moz-border-radius: $val1 $val2 $val3 $val4;
    -webkit-border-radius: $val1 $val2 $val3 $val4;
    border-radius: $val1 $val2 $val3 $val4;
}

@mixin opacity($opacity) {
    opacity: $opacity;
}

@mixin alpha-background-color($color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    background-color: $solid-color;
    background-color: $color;
}

@mixin translate3d($val1, $val2, $val3) {
    -webkit-transform: translate3d($val1, $val2, $val3);
    -moz-transform: translate3d($val1, $val2, $val3);
    -ms-transform: translate3d($val1, $val2, $val3);
    transform: translate3d($val1, $val2, $val3);
}

@mixin transition($val: 0.3s, $property: all, $ease: cubic-bezier(0, 0.8, 0.2, 1), $delay: 0) {
    -webkit-transition: $property $val $ease;
    -moz-transition: $property $val $ease;
    -o-transition: $property $val $ease;
    transition: $property $val $ease;
    -webkit-transition-delay: $delay;
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    transition-delay: $delay;
}

@mixin border-top-radius($radius) {
    background-clip: padding-box;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-right-radius($radius) {
    background-clip: padding-box;
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
    background-clip: padding-box;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
}

@mixin border-left-radius($radius) {
    background-clip: padding-box;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin animation($animation) {
    -webkit-animation: #{$animation};
    -moz-animation: #{$animation};
    -ms-animation: #{$animation};
    animation: #{$animation};
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}

@include keyframes(bounce) {
    0%,
    20%,
    50%,
    80%,
    100% {
        @include transform(translateY(0));
    }
    40% {
        @include transform(translateY(-6px));
    }
    60% {
        @include transform(translateY(-3px));
    }
}

@mixin placeholder($color, $size: "") {
    &::-webkit-input-placeholder {
        color: $color;
        @if $size !="" {
            font-size: $size;
        }
    }
    &:-moz-placeholder {
        color: $color;
        @if $size !="" {
            font-size: $size;
        }
    }
    &::-moz-placeholder {
        color: $color;
        @if $size !="" {
            font-size: $size;
        }
    }
    &:-ms-input-placeholder {
        color: $color;
        @if $size !="" {
            font-size: $size;
        }
    }
}

@mixin rotate($val) {
    -webkit-transform: rotate($val);
    -moz-transform: rotate($val);
    transform: rotate($val);
}

@mixin border-shadow($size, $color) {
    -webkit-box-shadow: 0 0 0 $size $color;
    -moz-box-shadow: 0 0 0 $size $color;
    box-shadow: 0 0 0 $size $color;
}

@mixin vertical-align() {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin horizontal-align() {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    }
    @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin text-emboss($alpha: 1) {
    text-shadow: rgba(0, 0, 0, $alpha) 0 -1px 0;
}

@mixin hide-text {
    display: block;
    overflow: hidden;
    text-indent: -9000rem;
}

@mixin navigation-list {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    >li {
        display: block;
        float: left;
        &:first-child {
            margin-left: 0;
        }
    }
}

@function calculateRem($size) {
    $remSize: calc($size / 16px);
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculaterem($size);
}

@mixin svg-bg($name, $extension: png, $color: transparent, $position: 50% 50%, $repeat: no-repeat) {
    background: url(../imgs/#{$name}.#{$extension}) $color $position $repeat;
    background: none,
    url(../imgs/#{$name}.svg) $color $position $repeat;
    background-size: 100% auto;
}