.section-lifestyle {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    background-color: $bianco;

    margin-top: 10vh;

    @include col-xs-sm() {
        margin-top: 0;
        min-height: 60vh;
    }

    .bg-holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        .bg {
            position: absolute;  
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
    }

    .section-lifestyle__wave {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            object-position: 0 calc(100% + 1px);
        }
    }

    .section-lifestyle__pp {
        position: absolute;
        bottom: 50px;
        right: 7vw;
        z-index: 3;
        max-width: 22vw;
        img {
            width: 100%;
            height: auto;
            display: block;
        }

        @include col-xs-sm() {
            max-width: 40vw;
            bottom: 0;
        }
       
    }

}
