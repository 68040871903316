.section-botanicals {
    position: relative;
    overflow: hidden;
    padding: 10vh 0 20vh var(--container-gap);

    background: radial-gradient(ellipse at 30% 50%, #ccf0f5 0%, #fff 50%, #fff 30%);

    @include col-xs-sm() {
        padding: 10vh var(--container-gap) 10vh var(--container-gap);
    }

    .section-botanicals__content {
        position: relative;
        z-index: 4;
        width: 60%;

        @include col-xs-sm() {
            width: 100%;
        }

        .section-title {
            margin-bottom: 80px;
            display: inline-flex;
        }

        .section-text {
            text-align: left;
        }

        .botanicals {
            display: grid;
            grid-gap: 7vw;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            @include col-xs() {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr 1f;
                grid-column-gap: 7vh;
            }

            @include col-sm() {
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                grid-column-gap: 7vh;
            }

            .botanicals--item {
                .botanicals--item__icon {
                    position: relative;
                    &::before {
                        content: "";
                        padding-bottom: 100%;
                        display: block;
                        background-color: $bianco;
                        border: 1px solid #CCC;
                        @include border-radius(50%, 50%, 50%, 50%);
                        position: relative;
                        z-index: 1;
                        @include transition(1s);
                    }
                    picture {
                        position: absolute;
                        z-index: 2;
                        @include transition(1s);
                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }
                }

                h5 {
                    @include font-bold();
                    font-size: 18px;
                    line-height: 1;
                    color: $nero;
                    text-align: center;
                    margin-top: 20px;
                    @include transition(1s);

                    @include col-md() {
                        font-size: 16px;
                    }

                    @include col-xs-sm() {
                        font-size: 16px;
                    }
                }
                cursor: pointer;
                &:hover {
                    .botanicals--item__icon {
                        &::before {
                            border-color: rgba($primary_color, 0.3);
                            @include border-shadow(9px, rgba($primary_color, 0.3));
                            transform: scale(0.9);
                        }
                        picture {
                            transform: scale(1.1);
                        }
                    }
                    h5 {
                        color: $primary_color;
                    }
                }

                &.botanicals--item__1 {
                    .botanicals--item__icon {
                        picture {
                            width: 70%;
                            top: -10%;
                            left: 15%;
                        }
                    }
                }
                &.botanicals--item__2 {
                    .botanicals--item__icon {
                        picture {
                            width: 100%;
                            top: 0;
                            left: 10%;
                        }
                    }
                }
                &.botanicals--item__3 {
                    .botanicals--item__icon {
                        picture {
                            width: 100%;
                            top: 20%;
                            left: -10%;
                        }
                    }
                }
                &.botanicals--item__4 {
                    .botanicals--item__icon {
                        picture {
                            width: 60%;
                            top: -20%;
                            left: 20%;
                        }
                    }
                }
                &.botanicals--item__5 {
                    .botanicals--item__icon {
                        picture {
                            width: 95%;
                            top: 5%;
                            left: 15%;
                        }
                    }
                }
                &.botanicals--item__6 {
                    .botanicals--item__icon {
                        picture {
                            width: 110%;
                            top: 18%;
                            left: -5%;
                        }
                    }
                }
                
            }
        }
    }

    .section-botanicals__bowl {
        position: absolute;
        top: 10%;
        right: 0;
        width: 30%;
        height: 80%;
        mix-blend-mode: darken;
        z-index: 3;
        overflow: hidden;

        picture {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 0;
            display: block;
        }

        @include col-xs-sm() {
            margin-top: 50px;
            position: relative;
            top: auto;
            right: auto;
            $double-gap: calc( var(--container-gap) * 2 );
            width: calc( 100% + $double-gap );
            height: auto;
            left: calc( 0% - var(--container-gap) );
            img {
                width: 130%;
                transform: translateX(-10%);
            }
            picture {
                position: relative;
            }
        }
    }

}
