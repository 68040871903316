#header {
    position: fixed;
    bottom: 0;
    left: 0;
    width: var(--header-width);
    height: var(--header-height);;
    z-index: 100;
    background-color: $primary_color;

    .header-label {
        position: absolute;
        top: var(--header-padding);
        left: 50%;
        transform: translateX(-50%);
        width: 30px;
        writing-mode: vertical-rl;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h3 {
            line-height: 30px;
            transform: rotate(180deg);
            white-space: nowrap;
            color: $bianco;
            font-size: var(--header-label-size);
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            @include font-bold();
            span {
                margin-top: var(--header-padding);
                @include font-light();
            }
        }

        @include col-xs-sm() {
           display: none;
        }
    }

    #logo {
        position: absolute;
        bottom: var(--header-padding);
        left: 50%;
        width: var(--header-logo-size);
        transform: translateX(-50%);

        @include col-xs-sm() {
            left: var(--header-padding);
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
        }

        a {
            display: block;
            @include transition(0.3s, transform, cubic-bezier(0.5, 0.1, 0.4, 0));
            img {
                width: 100%;
                height: auto;
                display: block;
            }
            &:hover {
                transform: scale(1.2);
            }
        }
    }

}