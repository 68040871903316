#navigation-mobile {
    background-color: darken($primary_color, 10);
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    .scroll-swiper-ajax-content {
        height: 100%;
        overflow: hidden;
        width: 100%;
        .swiper-slide {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: auto;
            .mobile-menu-back {
                position: absolute;
                top: 15px;
                left: 15px;
                width: 50px;
                height: 50px;
                cursor: pointer;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56.466' height='29.37' viewBox='0 0 56.466 29.37'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bfill:none;stroke:%23fff;%7D.b%7Bstroke-linecap:round;stroke-linejoin:round;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-919.034 -138.293)'%3E%3Cline class='a' x1='55.5' transform='translate(920 152.955)'/%3E%3Cpath class='b' d='M244.37,785.881l13.978,13.978L244.37,813.836' transform='translate(1177.882 952.836) rotate(180)'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: contain;
            }
            #nav-mob-holder {
                position: relative;
                line-height: 22px;
                width: 100%;
                min-height: 100vh;
                left: 0;
                display: flex;
                align-items: center;
                #mobile-menu {
                    list-style: none;
                    margin: 0;
                    width: 100%;
                    padding: 0 30px 0 30px;
                    counter-reset: nav-inc-counter;

                    li {
                        margin-top: 15px;
                        padding-top: 15px;
                        width: 100%;
                        border-top: 1px solid rgba(255, 255, 255, 0.1);
                        counter-increment: nav-inc-counter;
  
                        &:first-child {
                            margin-top: 0;
                            padding-top: 0;
                            border-top: none;
                        }
                        .item-rel-holder {
                            position: relative;
                            padding-right: 50px;
                            a {
                                color: $bianco;
                                display: inline-block;
                                font-size: 30px;
                                text-decoration: none;
                                @include font-title();
                                font-weight: normal;
                                letter-spacing: 1px;
                                text-transform: lowercase;

                                @include col-xs() {
                                    font-size: 25px;
                                }
                                
                                &:before {
                                    display: inline-block;
                                    margin-right: 20px;
                                    color: $primary_color;
                                    @include font-light();
                                    content: "0" counter(nav-inc-counter);
                                }

                            }
                            .caret {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                top: -2px;
                                position: absolute;
                                right: 0;
                                width: 26px;
                                height: 26px;
                                background-color: #08526F;
                                cursor: pointer;
                                @include border-radius(50%,
                                50%,
                                50%,
                                50%);
                                @include rotate(-90deg);
                            }
                        }
                        .dropdown-menu {
                            position: absolute;
                            top: 0;
                            left: 100%;
                            min-height: 100vh;
                            margin: 0;
                            width: 100%;
                            @include navigation-list();
                            padding: 120px 15px 0 15px;
                            overflow: visible;
                            background: rgb(0, 191, 178);
                            background: -moz-linear-gradient(162deg, rgba(0, 191, 178, 1) 0%, rgba(5, 102, 141, 1) 44%);
                            background: -webkit-linear-gradient(162deg, rgba(0, 191, 178, 1) 0%, rgba(5, 102, 141, 1) 44%);
                            background: linear-gradient(162deg, rgba(0, 191, 178, 1) 0%, rgba(5, 102, 141, 1) 44%);
                        }
                    }
                }
            }
        }
        .swiper-scrollbar {
            background-color: rgba(255, 255, 255, 0.2);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 3px;
            .swiper-scrollbar-drag {
                background-color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}
