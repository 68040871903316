.section-hero {
    position: relative;
    height: 100vh;
    background: radial-gradient(ellipse at left bottom, #c9edf3 0%, #fff 62%, #fff 100%);

    @include col-xs-sm() {
        height: auto;
        min-height: 100vh;
    }

    canvas {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        mix-blend-mode: multiply;
    }

    .section-hero__seaweed {
        position: absolute;
        z-index: 3;
        bottom: 0;
        right: var(--hero-bar-width);
        width: 15vw;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        pointer-events: none;

        @include col-xs-sm() {
            bottom: 0;
            right: 0;
            width: 40vw;
        }

        @include col-sm() {
            width: 30vw;
        }
    }

    .section-hero__bottle {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        right: var(--hero-bar-width);
        width: 40vw;
        mix-blend-mode: darken;
        pointer-events: none;
        img {
            width: 100%;
            height: auto;
            display: block;
        }

        @include col-xs-sm() {
            top: auto;
            bottom: var(--header-height);
            left: 50%;
            transform: translateX(-50%);
            right: auto;
            width: 90vw;
            position: relative;
        }
        @include col-sm() {
            width: 70vw;
        }

    }

    .section-hero__content {
        position: absolute;
        z-index: 4;
        left: var(--container-gap);
        width: 30vw;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: flex-end;


        @include col-xs-sm() {
            $container-gap-double: calc(var(--container-gap) * 2);
            width: calc( 100% - $container-gap-double );
            transform: none;
            align-items: center;
            top: 0;
            padding-top: 20vh;
            position: relative;
        }

        .section-hero__content--logo {
            width: 100%;

            max-width: 600px;
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        h2 {
            @include font-light();
            color: $primary_color;
            font-size: 26px;
        }
        h3 {
            @include font-bold();
            color: $nero;
            font-size: 20px;
            margin: 30px 0 70px 0;

            @include col-xs-sm() {
                margin: 20px 0 20px 0;
            }
        }

        .btn-prevendita {
            width: 100%;
            margin-bottom: 20px;
            font-size: 20px;
            height: 68px;
            line-height: 68px;
            background-color: #31ccda;
            border-color: #31ccda;

            &::before {
                background-color: darken(#31ccda, 10);
            }
        }
        .btn-experience {
            width: 100%;
            margin-bottom: 20px;
            font-size: 20px;
            height: 68px;
            line-height: 68px;
            background-color: #157780;
            border-color: #157780;
            

            &::before {
                background-color: darken(#156168, 10);
            }
        }
        .cta-container {
            display: flex;
            width: 100%;
            @include col-xs-sm() {
                display: none;
            }
            a {
                flex-grow: 1;
                margin-left: 20px;
                &:first-child {
                    margin-left: 0;
                }
            }
            @include col-md() {
                margin-top: 30px;
            }
            @include col-sm() {
                margin-top: 30px;
            }
            @include col-xs() {
                margin-top: 15px;
                a {
                    margin-left: 10px;
                    flex-grow: 1;
                }
            }
        }
    }

    .colors-bar {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: var(--hero-bar-width);
        display: flex;
        z-index: 3;

        @include col-xs-sm() {
            height: auto;
            flex-direction: column;
        }

        .colors-bar__item {
            flex-grow: 1;
            width: 25%;
            writing-mode: vertical-rl;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: var(--hero-bar-padding);

            @include col-xs-sm() {
                width: 100%;
                flex-grow: unset;
                padding: 0 20px;
                writing-mode: initial;
                flex-direction: row-reverse;
            }

            h5 {
                line-height: 30px;
                transform: var(--hero-bar-label-rotate);
                white-space: nowrap;
                color: $bianco;
                font-size: var(--hero-bar-label-size);
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                letter-spacing: 1px;
                @include font-light();
            }
            h4 {
                line-height: 30px;
                transform: var(--hero-bar-label-rotate);
                white-space: nowrap;
                color: $bianco;
                font-size: var(--hero-bar-label-size);
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                letter-spacing: 1px;
                @include font-bold();
            }

            &.colors-bar__item--1 {
                background-color: #EDAE49;
            }
            &.colors-bar__item--2 {
                background-color: #D1495B;
            }
            &.colors-bar__item--3 {
                background-color: #23A8A8;
            }
            &.colors-bar__item--4 {
                background-color: #414073;
            }
        }
    }

    .scroll-holder {
        position: absolute;
        right: var(--container-gap);
        width: 30px;
        height: 150px;
        border-right: 1px solid $color_gold;
        bottom: 0;
        z-index: 300;

        &::after {
            content: "";
            position: absolute;
            right: -1px;
            top: 100%;
            height: 100%;
            width: 1px;
            background-color: $bianco;
            
        }
        .scroll-down {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;

            .svg-scroll-txt {
                width: 6px;
                height: auto;
                display: block;
                margin-bottom: 10px;
            }

            .svg-scroll-arr {
                width: 10px;
                height: auto;
                display: block;
                margin-bottom: 10px;
                -moz-animation: bounce 2s infinite;
                -webkit-animation: bounce 2s infinite;
                animation: bounce 2s infinite;
            }
    
            @include col-xs() {
                // left: 20px;
                // transform: translateX(0);
            }
        }
    }
    

}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(5px);
    }
    60% {
        transform: translateY(4px);
    }
}