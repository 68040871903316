@import url('https://fonts.googleapis.com/css2?family=Gloock&family=Roboto:wght@300;700&display=swap');

//-----------------------------------------------------------------------

@mixin font-light() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 300;
}

@mixin font-bold() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 700;
}

@mixin font-title() {
	font-family: 'Gloock', serif;
	font-style: normal;
	font-weight: normal;
}
