.section-partner {
    position: relative;
    padding: 10vh var(--container-gap);

    .section-partner__boxes {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 -3vw;

        @include col-xs-sm() {
            flex-direction: column;
        }
        
        .section-partner__box {
            padding: 0 3vw;
            picture {
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }
    }

    .section-partner__content {
        position: relative;
        padding: 10vh 0;
        max-width: 800px;
        margin: 0 auto;
        .section-text {
            text-align: center;
        }
    }

    .section-partner__winner {
        background-color: brown;
        max-width: 800px;
        margin: 0 auto;
        picture {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
    
}
