#login {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9957;
    display: none;
    
    .login__holder {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bianco;
        .login__content {
            position: relative;
            max-width: 700px;
            padding: 5vw;
            margin: 5vw;

            text-align: center;
            @include border-radius(7px, 7px, 7px, 7px);
            background-color: $bianco;
            border: 5px solid $primary_color;

   
            h5 {
                @include font-bold();
                color: $primary_color;
                font-size: 20px;
                padding: 0;
                margin: 0 0 20px;
            }

            .login__content__fields {
                a {
                    min-width: 100px;
                }
            }
            #login__content__msg {
                text-align: center;
                @include font-bold();
                color: #C00;
                font-size: 16px;
                padding: 0;
                margin: 20px 0 0 0;
                display: none;
            }
        }
    }
}