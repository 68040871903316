p {
    @include font-light();
    line-height: 1.6;
    

    strong {
        @include font-bold();
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-title();
    margin: 0;
    padding: 0;
}

.section-title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h2 {
        @include font-title();
        color: $primary_color;
        line-height: 1.2;
        font-size: var(--section-title-size);
    }
    .section-title__ghirigoro {
        margin-top: 2vh;
        width: 10vw;

        @include col-xs-sm() {
            width: 30vw;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

.section-text {
    @include font-bold();
    color: $nero;
    font-size: var(--section-text-size);
    line-height: 1.5;
    text-align: right;
}

.text-ticker {
    width: 100%;
    overflow: hidden;

    @include col-xs() {
        bottom: 10px;
    }

    .text-ticker__inner {
        @include font-title();
        position: relative;
        left: 0;
        top: 0;
        font-size: 20vh;
        line-height: 1.2;
        white-space: nowrap;
        width: 200%;
        color: #e3f8fd;

        --marquee-width: 100vw;
        --offset: 20vw;
        --move-initial: calc(-25% + var(--offset));
        --move-final: calc(-50% + var(--offset));
        --item-font-size: 10vw;

        @include col-xs() {
            font-size: 10vh;
        }

        .marquee {
            width: var(--marquee-width);
            pointer-events: none;

            -webkit-backface-visibility: hidden;

            .marquee__inner {
                width: fit-content;
                display: flex;
                position: relative;
                transform: translate3d(var(--move-initial), 0, 0);
                animation: marquee 25s linear infinite;
                animation-play-state: paused;
                animation-play-state: running;
                transition-duration: 10s;

                span {
                    text-align: center;
                }
            }
        }

        @keyframes marquee {
            0% {
                transform: translate3d(var(--move-initial), 0, 0);
            }

            100% {
                transform: translate3d(var(--move-final), 0, 0);
            }
        }
    }
}