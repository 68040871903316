
#menu-toggle {
    position: absolute;
    background-color: transparent;
    border: none;
    width: var(--header-navigation-width);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: var(--header-navigation-height);;
    padding: 0;
    margin: 0;
    border: none;

    span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        top: 0;
        right: 0;
        background-color: $bianco;
        @include transition(0.1s, width, cubic-bezier(0.5, 0.1, 0.4, 0));

        &.nav-rect-1 {
            top: 0;
        }
        &.nav-rect-3 {
            top: 100%;
        }

        &.nav-rect-2 {
            top: 50%;
        }
        &.nav-rect-3 {
            top: 100%;
        }
    }
    &:hover {

        .nav-rect-1 {
            width: 50%;
        }
        .nav-rect-2 {
            width: 80%;
        }

    }

    // @include col-xs() {
    //     width: 55px;
    // }
}
    
