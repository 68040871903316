.section-filosophy {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20vh var(--container-gap) 25vh;
    background: radial-gradient(ellipse at 100% 70%, #ccf0f5 0%, #fff 50%, #fff 30%);

    @include col-xs-sm() {
        padding-top: 10vh;
        padding-bottom: 10vh;
        flex-direction: column-reverse;
    }

    .section-filosophy__bg {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 60%;
        z-index: 1;
        overflow: hidden;
        img {
            position: absolute;
            transform: translateX(25%);
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
        }
    }

    .section-filosophy__vert {
        position: absolute;
        z-index: 6;
        bottom: 0;
        right: calc( var(--container-gap) + 30px);
        
        writing-mode: vertical-rl;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 1px solid $primary_color;
        padding-bottom: 10vh;
        padding-right: 20px;
        width: auto !important;

        @include col-xs-sm() {
            padding-bottom: 5vh;
        }

        h3 {
            line-height: 30px;
            transform: rotate(180deg);
            color: $nero;
            font-size: 26px;
            line-height: 1;
            margin: 0;
            padding: 0;
            text-align: right;
            text-transform: uppercase;
            letter-spacing: 1px;
            @include font-bold();
            span {
                @include font-bold();
                font-size: 16px;
                display: block;
            }
        }
    }

    .text-ticker {
        position: absolute;
        top: 12%;
        left: 0;
        width: 100%;
        z-index: 2;

        @include col-xs-sm() {
            top: 8%;
        }
    }

    .section-filosophy__glass {
        width: 55%;
        mix-blend-mode: darken;
        position: relative;
        z-index: 3;
        @include col-xs-sm() {
            width: 100%;
            max-width: 600px;
            margin-top: 40px;
        }
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .section-filosophy__content {
        position: relative;
        z-index: 4;
        width: 42%;
        
        @include col-xs-sm() {
            width: 100%;
        }
    }

    .section-title {
        margin-bottom: 50px;
    }

    .section-text {
        text-align: right;
    }
}
