#footer {
    padding: 10vh var(--container-gap) 5vh var(--container-gap);

    @include col-xs-sm() {
        padding: 5vh var(--container-gap) 7vh var(--container-gap);
    }

    .footer--top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;
        border-top: 1px solid $nero;
        border-bottom: 1px solid $nero;

        @include col-xs-sm() {
            align-items: flex-start;
            flex-direction: column;
        }

        .footer--top__bottle {
            width: 200px;
            position: relative;
            picture {
                position: absolute;
                top: 0;
                transform: translateY(-150px);
                padding: 0 0 0 40px;
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
            @include col-xs-sm() {
                width: 160px;
                align-self: flex-end;
                picture {
                    transform: translateY(-80px);
                    padding: 0 40px 0 0;
                }
            }
        }

        .footer--top__text-box {
            p {
                margin: 0;
                padding: 0;
                @include font-light();
                font-size: 14px;
                color: $nero;
            }
            @include col-xs-sm() {
                margin: 10px 0;
            }
        }

        .footer--top__logo {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
            @include col-xs-sm() {
                margin-top: 30px;
                width: 100%;
            }
        }

    }

    a {
        display: inline-block;
        align-items: center;
        color: $nero;
        font-size: 14px;
        line-height: 22px;
        position: relative;
        @include font-light();
        padding: 0 5px;

        @include col-xs-sm() {
            padding: 0;
        }
    
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: $bianco;
            top: 0;
            right: 0;
            pointer-events: none;
            mix-blend-mode: difference;

            transform-origin: 0 50%;
            transform: scale3d(0, 1, 1);
            transition: transform 0.2s;
        }

        &:hover {
            &::after {
                transform-origin: 100% 50%;
                transform: scale3d(1, 1, 1);
            }
        }
    }


    .footer--bottom {
        padding: 30px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include font-light();
        font-size: 14px;
        color: $nero;

        @include col-xs-sm() {
            font-size: 12px;
            flex-direction: column-reverse;
            a {
                font-size: 12px;
            }
        }

        .footer--bottom__left {
            span {
                font-size: 11px;
            }
        }

        .footer--bottom__right{
            //background-color: rgb(42, 138, 165);
        }

    }
}
