#progress-bar {
    position: fixed;
    width: var(--header-width);
    height: var(--progress-bar-height);
    bottom: var(--progress-bar-bottom);
    left: 0;
    z-index: 995;
    @include box-shadow( 0, -3px, 3px, rgba(0,0,0,.2) );

    progress {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 100%;
        border: none;
        background: $nero;
    }

    progress::-webkit-progress-bar {
        background: $nero;
      }
      progress::-webkit-progress-value {
        background: $primary_color;
        background-attachment: fixed;
      }
      progress::-moz-progress-bar {
        background: $primary_color;
        background-attachment: fixed;
      }
}